/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import './variables.scss';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$examweb-red: (
  50: #fddee1,
  100: #f796a0,
  200: #f46271,
  300: #ef1f35,
  400: #e01026,
  500: #c30e21,
  600: #a60c1c,
  700: #8a0a17,
  800: #6d0813,
  900: #51060e,
  A100: #ffd1d6,
  A200: #ff6b7b,
  A400: #ff051f,
  A700: #e10a20,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

// TODO: 暫定で material の blue をそのまま利用しています。
$examweb-blue: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$examweb-primary: mat.define-palette($examweb-red);
$examweb-accent: mat.define-palette($examweb-blue, A200, A100, A400);

// TODO: 暫定です。
$examweb-warn: mat.define-palette(mat.$red-palette);

$examweb-theme: mat.define-light-theme(
  (
    color: (
      primary: $examweb-primary,
      accent: $examweb-accent,
      warn: $examweb-warn
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

@include mat.all-legacy-component-themes($examweb-theme);

// $custom-typography: mat-typography-config(
//   $font-family: $font-family-default
// );
// @include angular-material-typography($custom-typography);

// #693
$my-custom-typography-config: mat.define-legacy-typography-config(
  $input: mat.define-typography-level(14px, 1.4, 400),
  $font-family: $font-family-default
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($my-custom-typography-config);`
@include mat.all-legacy-component-typographies($my-custom-typography-config);
@include mat.legacy-core();

// tabフォーカスのデフォルトスタイル
:focus-visible {
  outline: 1px dotted $color-primary;
}
.mat-button-base.cdk-keyboard-focused {
  outline: 1px dotted $color-primary;
}

// :active ボタンのスタイル、aria-pressed="true" をあわせる
button:active,
button[aria-pressed='true'] {
  outline: none;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  // height: 100%;
  width: 100%;
}

body {
  margin: 0;
  // font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-family: $font-family-default;
  background: $color-bg-app;
  @media print {
    background-color: white;
  }
}

// TODO: デザインが決まったら変更
.error-code-color {
  color: $grey-600;
}

.snack-bar-zero-padding-panel-class {
  padding: 0 !important;
}

.dialog-zero-padding-panel-class {
  mat-dialog-container {
    padding: 0;
  }
}

.progress-48 {
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
}

.progress-48-search-result-loading {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-cell {
  color: black;
}

/**
 * override
 */

// modal dialogの幅をresponsiveにする
.cdk-overlay-container {
  .cdk-overlay-pane {
    max-width: 100vw !important;
    max-height: calc(100vh - 5rem);
    max-height: calc(100dvh - 2rem);
  }
  .mat-dialog-container {
    border-radius: 0.5rem;

    @include mqDown('sm') {
      border-radius: 1rem;
    }
  }

  // dialogの左右に余白を設けるためのスタイル（フレームワークを内側から上書きするために 余白をつけたいモーダルに対して .override-premium-dialog-styles クラスを付与）
  &:has(.override-premium-dialog-styles) {
    @include mqDown('sm') {
      .cdk-overlay-pane {
        max-width: 400px !important;
        margin-left: 2.25rem;
        margin-right: 2.25rem;
      }
    }
  }
}

// AngularMaterial のバージョンアップで必須項目に「※」が表示されるようになってしまったため
.mat-placeholder-required {
  display: none;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  @include mqDown('sm') {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

// mat-button 四角ボタン内に mat-icon を置いた際に、適切なサイズ感に調整する
.mat-button-base {
  &.mat-button,
  &.mat-stroked-button,
  &.mat-flat-button {
    .mat-icon {
      width: 1em;
      height: 1em;
      font-size: 1.2em;
      margin-top: -1px;
    }
  }

  // 極小ボタン
  &.mat-button-mini {
    padding: 0 0.5em;
    line-height: 1.75;
    min-width: initial;
    font-size: 0.625rem;
  }
}

// mat-flat-buttonのdisabled時の色をglobal管理
.mat-button-base.mat-flat-button.mat-button-disabled {
  background: $gray-100 !important;

  .mat-icon {
    fill: rgba($black, 0.25) !important;
    color: rgba($black, 0.25) !important;
  }
}

// mat-table
.mat-table {
  .mat-header-row .mat-header-cell {
    background: $color-bg-thead;
    border-bottom: none;
    font-weight: normal;
    font-size: 0.75rem;
    color: $color-text;
  }

  .mat-header-cell,
  .mat-cell,
  .mat-footer-cell {
    padding-left: $m * 1;
    padding-right: $m * 1;
    padding-top: $m * 1;
    padding-bottom: $m * 1;
    border-bottom-color: $color-border-line;
  }

  .mat-cell a {
    color: $color-primary;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  // テーブルホバー時の色
  tr.mat-row:hover {
    @media (hover: hover) {
      background-color: rgba($color-primary, 0.03);
    }
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: $m * 2;
  }

  .mat-icon-button {
    line-height: 1;
    // .mat-icon {
    //   font-size: 1.25rem;
    // }
  }
}

/**
 * LP(top)ページ用 スタイル
 */
body.page-top {
  /**
   * TOPページのみレスポンシブ対応の例外的なデザインとする
   */
  .root-container {
    min-width: initial;
  }
}

/**
 * 印刷ページ用 スタイル
 */
body.page-print-preview {
  background: $white;

  .mat-table {
    .mat-header-row {
      .mat-header-cell {
        background-color: transparent;
        border-top: 2px solid $color-text;
        border-bottom: 2px solid $color-text;
      }
    }

    .mat-cell,
    .mat-footer-cell {
      border-bottom-color: lighten($color-text, 75%);
    }

    tr.mat-row:hover {
      background-color: transparent;
    }
  }
}

/**
 * プリント時の表示コントロール
 */
// プリント時のみ表示させない
[data-no-print] {
  @media print {
    visibility: hidden;
    display: none;
  }
}

// プリント時のみ表示させる
[data-print-only] {
  visibility: hidden;
  display: none;

  @media print {
    visibility: visible;
    display: block;
  }
}

// ************************************************
// Global animations

// #3224 ご意見箱バナー（bottom-toast-banner） 用 slide-in animation
@keyframes bottom-toast-banner-slide-in {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

// ************************************************

/**
 * tailwindcss init
 */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

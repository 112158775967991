/**
 * MVP版 ~
 */

$font-family-default: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Hiragino Sans', 'Noto Sans CJK JP',
  'Original Yu Gothic', 'Yu Gothic', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Sans Emoji';

// TODO: コンテンツの表示状況を見て調整
$max-width: 1280px;
$min-width: 850px;

$selected-categories-width: 700px;
$selected-categories-max-height: 200px;

$m: 8px; // margin単位

$margin-big: $m * 3;
$margin-standard: $m * 2;
$margin-small: $m * 1;

$padding-big: $m * 3;
$padding-standard: $m * 2;
$padding-small: $m * 1;

$border-radius: 6px;

$grey-300: #e0e0e0;
$grey-600: #757575;

$examweb-red-50: #fddee1;
$examweb-red-200: #f46271;

// TODO: アプリのカラーに合わせる
$warn: red;

// 結果表示の table 用
$table-sequential-id-width: 80px;
$table-university-width: 150px;
$table-departments-width: 150px;
$table-year-width: 80px;
$table-level-width: 100px;
$table-book-width: 90px;
$table-page-width: 70px;
$table-problem-number-width: 100px;
$table-word-download-width: 72px;

/**
 * 製品版 2021年~
 */

// tailwind.config.js と　同値
$breakpoints: (
  'xs': 600px,
  'sm': 780px,
  'md': 900px,
  'lg': 1200px,
  'xl': 1800px
);

$min-viewport-width: 900px; // ページ全体の最小幅
$top-viewport-max: 1500px; // TOPページ全体の最大幅

$bottom-tab-ui-height: 64px; // sp版下部UIの高さ

// z-index
// ※ モーダル(.cdk-overlay-container が 1000 なので注意)
$z-sign-in-header: 1000;
$z-top-header: 990; // LPのSP版ヘッダ
$z-bottom-toast-banner: 910; // toastバナー
$z-bottom-ui: 900; // BtoCのSP版下部UI
$z-search-summary: 890; // BtoC検索結果のSP版画面上部追従部分

// color (MEMO: tailwind.config.js と値を合わせる)
$black: #000000;
$gray-900: #1a1a1a;
$gray-800: #333333;
$gray-700: #4d4d4d;
$gray-600: #666666;
$gray-500: #808080;
$gray-400: #999999;
$gray-300: #b3b3b3;
$gray-200: #cccccc;
$gray-100: #e6e6e6;
$gray-50: #f2f2f2;
$gray-30: #f7f7f7;
$white: #ffffff;
$yellow-gold-lighter: #f9f3d0;
$yellow-gold: #e3c316;
$yellow-gold-dark: #c3ab27;
$yellow-gold-darker: #9f890f;

// semantic color
$primary-50: #ffebef;
$primary-100: #fcd9cd;
$primary-200: #f9ac9c;
$primary-300: #ec7369;
$primary-400: #db4244;
$primary-500: #c30e21;
$primary-600: #a70a29;
$primary-700: #8c072d;
$primary-800: #71042d;
$primary-900: #5d022d;
$success-50: #f0f9e8;
$success-100: #effbd5;
$success-200: #dcf8ac;
$success-300: #bcea7e;
$success-400: #9bd55b;
$success-500: #6fba2c;
$success-600: #559f20;
$success-700: #3f8516;
$success-800: #2b6b0e;
$success-900: #1d5908;
$info-50: #e0f4fe;
$info-100: #c9f6fc;
$info-200: #95e7f9;
$info-300: #5fccef;
$info-400: #38ace0;
$info-500: #0081cc;
$info-600: #0064af;
$info-700: #004a92;
$info-800: #003476;
$info-900: #002561;
$warning-50: #fdf7e1;
$warning-100: #fef3cb;
$warning-200: #fde398;
$warning-300: #fbce64;
$warning-400: #f7b93d;
$warning-500: #f39800;
$warning-600: #d07a00;
$warning-700: #ae6000;
$warning-800: #8c4800;
$warning-900: #743700;
$danger-50: #ffebef;
$danger-100: #fcd9cd;
$danger-200: #f9ac9c;
$danger-300: #ec7369;
$danger-400: #db4244;
$danger-500: #c30e21;
$danger-600: #a70a29;
$danger-700: #8c072d;
$danger-800: #71042d;
$danger-900: #5d022d;

// color rule asign
$color-text: $black;
$color-text-sub: $gray-500;
$color-text-nega: $gray-300;
$color-bg-content: $white;
$color-bg-app: $gray-50;
$color-bg-thead: rgba($black, 0.05);
$color-bg-thead-in-detail: rgba($black, 0.025);
$color-bg-header: $white;
$color-bg-footer: $gray-50;
$color-bg-section: rgba($gray-50, 0.25);
$color-bg-plan-members-item: $gray-30;
$color-border-line: #e8e8e8;
$color-primary: #c30e21; // $examweb-red 500
$color-primary-extra-light: #fbf1f2; // $color-primary の 6%
$color-danger: red;
$color-visited: #81007f;
$color-premium: $yellow-gold;
$color-premium-dark: $yellow-gold-dark;
$color-premium-darker: $yellow-gold-darker;
$color-bg-answed-spinner: rgba($black, 0.3);
$color-entrance-hover-bg: #fff2f2;

// 大学別カテゴリマークの色
$theme-univ-type: (
  other: (
    color: #003f53,
    backgroundColor: #b3e5f4
  ),
  kokuritsu: (
    color: #003f53,
    backgroundColor: #b3e5f4
  ),
  shiritsu: (
    color: #7e4108,
    backgroundColor: #ffe7aa
  ),
  kouritsu: (
    color: #275a07,
    backgroundColor: #b9efa5
  )
);

// 教科色
$theme-subject: (
  eigo: (
    color: $white,
    backgroundColor: #0082cb
  ),
  sugaku: (
    color: $white,
    backgroundColor: #78bd36
  ),
  kokugo: (
    color: $white,
    backgroundColor: #ec6500
  ),
  butsuri: (
    color: $white,
    backgroundColor: #009d91
  ),
  kagaku: (
    color: $white,
    backgroundColor: #d7053d
  ),
  seibutsu: (
    color: $white,
    backgroundColor: #df5280
  ),
  nihonshi: (
    color: $white,
    backgroundColor: #f39800
  ),
  sekaishi: (
    color: $black,
    backgroundColor: #fff100
  ),
  chiri: (
    color: $white,
    backgroundColor: #920783
  ),
  seikei: (
    color: $white,
    backgroundColor: #1d2088
  )
);

// particle colors
$theme-particle-dots: (
  #0081cc,
  #6fba2c,
  #f39800,
  #e60039,
  #e0d400,
  #007440,
  #1d2088,
  #009e96,
  #ed6c00,
  // #e95383,
    // #007440,
    // #fff100,
);

// トライ様データ用
$theme-try: (
  r: (
    color: #c30e21,
    backgroundColor: rgba(#c30e21, 0.1)
  ),
  g: (
    color: #1ca80c,
    backgroundColor: rgba(#1ca80c, 0.1)
  ),
  b: (
    color: #0e7bc3,
    backgroundColor: rgba(#0e7bc3, 0.1)
  )
);

/**
 * mixins
 */

// レスポンシブ指定用フレームワーク (参: https://github.com/rm-labo/mq-scss)
$mq-breakpoints: (
  xs: map-get($breakpoints, xs),
  sm: map-get($breakpoints, sm),
  md: map-get($breakpoints, md),
  lg: map-get($breakpoints, lg),
  xl: map-get($breakpoints, xl)
);
$mq-breakpoints-default-key: 'md';
@import '../node_modules/@rm-labo/mq-scss/_mq.scss';
